<!--
 * @Author: ChenYaJin
 * @Date: 2023-07-07 14:25:49
 * @LastEditors: wjc
 * @LastEditTime: 2023-12-04 19:13:58
 * @Description: 弹窗组件
-->

<template>
  <ClientOnly>
    <el-dialog
      v-bind="$attrs"
      :class="props.class"
      :width="width"
      :draggable="true"
      :title="modalData.title"
      :append-to-body="true"
      :model-value="isShow"
      :close-on-click-modal="false"
      @open="open"
      @close="close"
    >
      <template v-if="$slots.header" #header>
        <slot name="header"></slot>
      </template>
      <div class="h-400px overflow-y-auto">
        <slot></slot>
      </div>
      <slot name="footer">
        <div v-if="footerShow" class="footer">
          <div v-if="isDelete">
            <el-button link type="danger" class="delete-button" @click="onDelete">删除</el-button>
          </div>
          <div v-if="!isClose">
            <el-button @click="close">取消</el-button>
            <el-button
              type="primary"
              :loading="confirmLoading"
              element-loading-custom-class="confirm-loading"
              :disabled="confirmDisabled"
              @click="submit"
            >
              {{ confirmText ? confirmText : '确认' }}
            </el-button>
          </div>
          <div v-else style="text-align: center">
            <el-button @click="close">关闭</el-button>
          </div>
        </div>
      </slot>
    </el-dialog>
  </ClientOnly>
</template>

<script setup lang="ts">
  defineOptions({
    name: 'MDialog',
  })
  const props = defineProps({
    class: {
      // 支持弹框自定义样式
      type: String,
      default() {
        return 'class'
      },
    },
    /**
     * 弹框信息
     */
    modalData: {
      type: Object,
      default() {
        return {
          isShow: false,
          title: '提示',
          row: null,
        }
      },
    },
    // 弹框宽度
    width: {
      type: String,
      default() {
        return '700px'
      },
    },
    // 确认加载
    confirmLoading: {
      type: Boolean,
      default() {
        return false
      },
    },
    // 是否显示底部
    footerShow: {
      type: Boolean,
      default() {
        return true
      },
    },
    confirmDisabled: {
      type: Boolean,
      default() {
        return false
      },
    },
    // 确认按钮文字
    confirmText: {
      type: String,
      default() {
        return ''
      },
    },
    // 仅预览模式：只显示关闭按钮
    isClose: {
      type: Boolean,
      default() {
        return false
      },
    },
    // 显示删除按钮
    isDelete: {
      type: Boolean,
      default() {
        return false
      },
    },
  })
  // const emits = defineEmits<{
  //   open: []
  //   close: []
  //   delete: [data: unknown]
  //   submit: [data: unknown]
  // }>()
  const emits = defineEmits(['submit', 'open', 'close', 'delete'])
  const isShow = computed(() => props.modalData.isShow)

  const submit = () => {
    emits('submit', props.modalData)
  }
  const open = () => {
    emits('open')
  }
  const close = () => {
    emits('close')
  }
  const onDelete = () => {
    emits('delete')
  }
</script>

<style scoped lang="scss">
  .footer {
    text-align: center;
    padding-top: 20px;
  }
  .delete-button {
    @apply position-absolute left-2rem mt-0.5rem;
  }
</style>
